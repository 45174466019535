<template>
  <div>
    <b-card-code title="Create new Delivery Note">
      <b-card-text>
        <span>Please enter Delivery Note details</span>
      </b-card-text>

      <!-- form -->
      <validation-observer ref="simpleRules">

        <validation-observer ref="simpleRules">
          <div>
            <div>
              <b-form
                ref="form"
                :style="{ height: trHeight }"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >

                <b-row>
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      label="Delivery Note number"
                      label-for="Delivery Note number"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Delivery Note number"
                        rules="required"
                      >
                        <b-form-input
                          v-model="delivery_note.number"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Delivery note number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="Customer"
                      label-for="Customer"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Customer"
                        rules="required"
                      >
                        <label class="d-inline d-lg-none">Customer</label>
                        <v-select
                          v-model="customer"
                          placeholder="Select Item"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="customers"
                          label="code"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in items"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <!-- produit -->
                  <b-col md="4">
                    <b-form-group
                      label="Product"
                      label-for="Product"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Produit"
                        rules="required"
                      >
                        <label class="d-inline d-lg-none">Item</label>
                        <v-select
                          v-model="item.produit"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="itemsOptions"
                          label="item_code"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Select Item"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Quantity -->
                  <b-col md="4">
                    <b-form-group
                      label="NBR . CTN"
                      label-for="quantity"
                    >
                      <b-form-input
                        v-if="item.produit.item_code"
                        id="quantity"
                        v-model="item.quantity"
                        type="number"
                        placeholder="Quantity"
                        :on-change="alertt(item.quantity*item.produit.nbr_export,item.produit.stock_received_tunisia,item,index)"
                      />
                      <b-form-input
                        v-else
                        id="quantity"
                        type="number"
                        placeholder="Number of carton"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group
                      label="NBR . PCS / CTN"
                      label-for="NBR . PCS / CTN"
                    >
                      <b-form-input
                        v-model="item.produit.nbr_export"
                        placeholder="Number of piece by carton"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Total . NBR . PCS"
                      label-for="NBR . PCS"
                    >
                      <b-form-input
                        v-if="item.produit.item_code"
                        v-model="item.produit.nbr_export * item.quantity"
                        placeholder="Total number of piece"
                      />
                      <b-form-input
                        v-else
                        placeholder="Total Number of piece"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Sales price / CTN"
                      label-for="PA"
                    >
                      <b-form-input
                        v-if="item.produit.item_code"
                        v-model="item.produit.pv_ctn_export"
                        placeholder="Total number of piece"
                      />
                      <b-form-input
                        v-else
                        placeholder="Total Number of piece"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Total . Sales price"
                      label-for="PA"
                    >
                      <b-form-input
                        v-if="item.produit.item_code"
                        v-model="item.produit.pv_ctn_export * item.quantity"
                        placeholder="Total number of piece"
                      />
                      <b-form-input
                        v-else
                        placeholder="Total Number of piece"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="6"
                    class="mt-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>Delete</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-form>
            </div>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1"
              @click="repeateAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>New product</span>
            </b-button>
            <b-row v-if="somme">
              <b-col
                md="12"
                class="mt-2 mr-2"
              >
                <b-form-group
                  label="Total cost price"
                  label-for="Total cost price"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Total cost price"
                    rules="required"
                  >
                    <b-form-input
                      v-model="somme.toFixed(2)"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Total cost price"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row />
            <b-row>

              <b-col md="12">
                <b-form-group
                  label="Comment"
                  label-for="Comment"
                >
                  <b-form-textarea
                    id="textarea-rows"
                    v-model="delivery_note.comment"
                    placeholder="Comment"
                    rows="8"
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
              <b-col cols="12">
                <b-button
                  variant="primary"
                  type="submit"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-3"
                  @click.prevent="addDeliveryNote"
                >
                  Create
                </b-button>
                <b-button
                  variant="outline-secondary"
                  type="reset"
                  class="mt-3"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>

          </div>
        </validation-observer>
      </validation-observer>
    </b-card-code></div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

/* import vSelect from 'vue-select' */
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [
        {
          id: 1,
          type: '',
          produit: 0,
          quantity: 1,
        },
      ],
      itemsOptions: [],
      customers: [],
      nextTodoId: 1,
      customer: { code: '' },
      delivery_note: {
        comment: '',
        status: 0,
        number: '',
      },
      required,
    }
  },

  computed: {
    invoicenum() {
      return this.invoices.length
    },
    generate() {
      return Number(this.invoicenum) + 1
    },
    somme() {
      return this.items.reduce((accumulator, item) => accumulator + (item.produit.pv_ctn_export * item.quantity), 0)
    },

  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    // this.user = storeAuth.state.user
    // window.addEventListener('resize', this.initTrHeight)
    this.getProducts()
    this.getCustomers()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    alertt(quantity, inTunisia, item, index) {
      if (quantity > inTunisia) { this.withTitle(inTunisia, item, index) }
    },
    withTitle(quantity, item, index) {
      this.$swal({
        title: 'Stock Alerte',
        icon: 'warning',
        text: `Quantity is greater than stock, you have ${quantity} PCS`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeItem(index)
        }
      })
    },
    async getCustomers() {
      try {
        const { data } = await axiosIns.get('/customers/', { params: { is_deleted: false } })
        this.customers = data
      } catch (error) {
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    async getProducts() {
      try {
        const { data } = await axiosIns.get('/products/', { params: { is_deleted: false } })
        this.itemsOptions = data
      } catch (error) {
        this.showToast('danger', 'top-center', error.toString())
      }
    },

    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += 1,
        type: '',
        produit: 0,
        quantity: 1,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    async addDeliveryNote() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line

          await axiosIns.post('/delivery-notes/create-all/', {
            number: this.delivery_note.number,
            comment: this.delivery_note.comment,
            status: 0,
            customer: this.customer.id,
            total_pv: this.somme.toFixed(2),
            products: this.items,
          }).then(() => {
            this.$router.push({ name: 'delivery-notes' })
            this.showToast('success', 'top-center', 'order successfully created')
          })
            .catch(error => {
              this.showToast('danger', 'top-center', error.toString())
            })
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
